@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}
html {
  scroll-behavior: smooth;
}

.horScroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #e1e1e1;
}

.horScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

.horScroll::-webkit-scrollbar-thumb {
  background: #028006;
  border-radius: 10px;
}

.horScroll::-webkit-scrollbar-thumb:hover {
  background: #a5a7a8;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 3rem;
}

.swiper-slide img {
  display: block;
  width: 100vh;
  height: 250px;
  object-fit: cover;
}

@media (max-width: 1020px) {
  .swiper-slide img {
    width: 200px;
    height: 200px;
  }
  .bg-grad {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  }
}
@media (max-width: 768px) {
  .swiper-slide img {
    width: 150px;
    height: 100px;
  }
}
